import React from 'react';
import { Service } from './Service';
import { faSearch, faCode, faCloud, faProjectDiagram } from '@fortawesome/free-solid-svg-icons'

export function Services() {

  const serviceList = [{
    title: 'Software Engineering',
    desc: 'Spinlock Security can offer software engineering services. The team has extensive experience working and novel solutions across a variety of technology stacks and business objectives. The Software Development Lifecycle leveraged by the team allows for regular iterative value to be realised by customers and is tailored to working in highly secure environments.',
    icon: faCode
  }, {
    title: 'Platform and Cloud Infrastructure',
    desc: 'Spinlock Security is well placed to provide a number of platform-related services covering a range of disciplines including networking, data centre, compute, core Internet services and cloud platforms. The Spinlock Security team have a lot of knowledge and experience with the security aspects of these technical disciplines and are keen to help conceive and create secure infrastructure-based solutions for the Australian Government.',
    icon: faCloud
  }, {
    title: 'Data Science and Machine Learning',
    desc: 'Spinlock Security can bring a team of seasoned Data Scientists and Machine Learning Engineers to develop a range of bespoke and well-engineered capabilities. The team has experience in implementing scalable end-to-end AI/ML systems covering descriptive, predictive and prescriptive analytics. Spinlock Security’s engineering approach covers both development and production environments and includes CI/CD/CT processes, code-data-model versioning, container/model registries, deployment, serving and monitoring.',
    icon: faProjectDiagram
  },{
    title: 'Research and Development',
    desc: 'The Spinlock Security team have extensive experience in research and development programmes in computer science and machine learning. Many of these research programmes have been focused within the cyber security domain.',
    icon: faSearch
  }
  ]

  return (

    <div class="py-12 bg-white">
      <div class="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="lg:text-center">
          <p class="text-base leading-6 text-gray-400 font-semibold tracking-wide uppercase">Our Services</p>

          <p class="mt-4 mb-10 max-w-2xl text-xl leading-7 text-gray-600 lg:mx-auto">
            {/* Tagline can go here */}
          </p>
        </div>

        <div class="mt-10">
          <ul class="md:grid md:grid-cols-2 md:col-gap-20 md:row-gap-10">
            {serviceList.map(service => (<li class="mt-10 md:mt-0"><Service title={service.title} desc={service.desc} icon={service.icon} /></li>))}

          </ul>
        </div>
      </div>
    </div>

  );
}
