import React from 'react';
import { Hero } from './features/hero/Hero';
import { Services } from './features/services/Services';
import { Contact } from './features/contact/Contact';


function App() {
  return (
    <div class="font-body tracking-wide">
        <Hero />
        <Services />
        <Contact />
    </div>
  );
}

export default App;
