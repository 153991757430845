import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export function Service(props) {

  return (
    <div class="flex">
      <div class="flex-shrink-0">
        <div class="flex items-center justify-center h-12 w-12 rounded-md bg-sls-accent-1 text-white">
          <FontAwesomeIcon icon={props.icon} />
        </div>
      </div>

      <div class="ml-4">
        <h4 class="text-xl my-3 leading-6 font-semibold text-gray-900 font-body" >{props.title}</h4>
        <p class="mt-2 text-base leading-7 text-gray-600 font-body">
          {props.desc}
        </p>
      </div>

    </div>
  );
}
