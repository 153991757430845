import React from 'react';

export function Contact() {

  return (
    <div class="relative bg-gray-100">
      <div class="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="relative max-w-7xl mx-auto lg:grid lg:grid-cols-5">

          <div class="bg-gray-50 py-8 px-4 sm:px-6 sm:pt-10 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
            <div class="max-w-lg mx-auto">
              <a name="contact" href=".">
                <h2 class="text-2xl font-heading tracking-wide text-gray-900 sm:text-3xl sm:leading-9">
                  Get in touch
                </h2>
              </a>
              <p class="mt-3 text-lg leading-6 text-gray-500">
                {/* Tagline can go here */}
              </p>
            </div>
          </div>

          <div class="bg-gray-100 pb-8 px-4 sm:pb-8 xs:py-8 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
            <div class="max-w-lg mx-auto lg:max-w-none">

              <div class="lg:text-right">
                <h4 class="text-lg leading-6 font-medium text-gray-900 lg:mt-8">
                  General enquiries
                </h4>
                <dl class="mt-2 text-base leading-6 text-gray-500">
                  <div>
                    <dt class="sr-only">Email</dt>
                    <dd>support@example.com</dd>
                  </div>
                  <div class="mt-1">
                    <dt class="sr-only">Phone number</dt>
                    <dd>+1 (555) 123-4567</dd>
                  </div>
                </dl>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}


