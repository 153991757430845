import React from 'react';
import logo from '../../logo.png';
import sls from '../../sls-background.svg';

export function Hero() {

  return (
    <div class="bg-gradient-to-r to-sls-dark-2 from-sls-dark-1  relative overflow-hidden">
      <div class="demo right-0 overflow-hidden">
      <div class="max-w-screen-xl mx-auto">
        <div class="relative z-10 pb-6 sm:pb-10 md:pb-10 lg:max-w-2xl lg:w-full lg:pb-40 xl:pb-56">

          <div class="relative pt-6 px-4 sm:px-6 lg:px-8">
            <div class="relative flex items-center justify-between sm:h-10 lg:justify-start">
              <div class="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
                <div class="flex items-center justify-between w-full md:w-auto">
                  <img class="h-12 lg:h-20 w-auto sm:h-16" src={logo} alt="Spinlock logo" />
                </div>
              </div>
            </div>
          </div>

          <main class="mt-8 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-40 lg:px-8 xl:mt-28">
            <div class="sm:text-center lg:text-left">
              <h2 class="text-3xl leading-10 font-heading font-thin tracking-wide text-gray-100 sm:text-5xl sm:leading-none md:text-6xl">
                Tailor made technical security you can trust
              </h2>
              <p class="mt-6 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-8 md:text-lg lg:mx-0">
                {/* Tagline can go here */}
              </p>

              <div class="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                <a href="#contact">
                  <button type="button" class="inline-flex items-center px-6 py-2 border border-transparent text-base tracking-wide font-medium text-lg rounded-md text-sls-dark bg-sls-accent-2 hover:bg-sls-accent-1 focus:outline-none focus:border-sls-accent-1 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150">
                    Get in touch
                  </button>
                </a>
              </div>
            </div>
          </main>
        </div>
      </div>
      <div class="lg:absolute lg:inset-y-0 lg:right-0 lg:w-3/5">
        <img class="relative right-0 top-0 -pt-20 opacity-25 h-40 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full" src={sls} alt="Spinlock logo background" />
      </div>
    </div>
    </div>

  );
}
